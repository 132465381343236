import React from "react"
import LayoutXT from "../components/LayoutXT"

export default function Auseinandersetzung() {
  return (
    <LayoutXT>
      <h1 className="left">Auseinandersetzung einer Erbengemeinschaft</h1>
      <div className="rem-of-13 gray-a lheight18">
        Sind vom Erblasser mehrere Erben eingesetzt, entsteht eine
        Erbengemeinschaft, welche nach dem gesetzgeberischen Leitbild eine
        Gemeinschaft ist, welche unter den Erben aufzulösen ist. Oftmals ist
        jedoch die Verteilung der Erbschaft unter den Erben umstritten,
        insbesondere wenn die Erbfolge nicht juristisch fundiert und transparent
        geregelt ist. Dann gibt es oftmals langwierigen Streit unter den Erben,
        welcher Erbe wie viel vom Erbe bekommt.
        <br />
        <br />
        Wir beraten Sie als Erbe innerhalb einer Erbengemeinschaft und
        entwickeln für Sie steueroptimierte Lösungen für eine erfolgreiche und
        zügige Abwicklung der Gemeinschaft, denn dies erspart viele Wege und
        Kosten und verhilft Ihnen zu Ihrem rechtmäßigen Erbe. <br />
        <br />
        Insbesondere bei Immobilien und bei dem Betrieb eines Unternehmens ist
        eine fundierte Auseinandersetzung unerlässlich, denn solange eine
        Erbengemeinschaft besteht müssen alle wichtigen und grundlegenden
        Entscheidungen zusammen und teils sogar einstimmig getroffen werden. Ist
        keine Einigung möglich, können massive Wertverluste drohen, da jeder
        Erbe auch die Verwertung von z.B. einer Immobilie beantragen kann. Gerne
        begleiten wir Sie auch bei teils emotional gefärbten Besprechungen im
        Kreis der Erbengemeinschaft.
        <br />
        <br />
        Ziel ist selbstverständlich eine außergerichtliche Auseinandersetzung
        mit den übrigen Erben, doch notfalls werden wir Ihr Recht auch vor
        Gericht mit den notwendigem Fachwissen und Praxiserfahrung vertreten und
        durchsetzen.
      </div>
    </LayoutXT>
  )
}
